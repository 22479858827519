import styled from "@emotion/styled";
import React, {Component} from "react";
import {FaBomb} from "react-icons/fa";
import tinycolor from "tinycolor2";
import {UiButton} from "ui/button";
import {UiCol, UiContainer, UiRow} from "ui/grid";

const ErrorIcon = styled.div`
  font-size: 110px;
  color: hsl(355, 100%, 60%);
`;

class ErrorBoundary extends Component {
    state = {
        hasError: false,
        data: null
    };

    static getDerivedStateFromError(error) {
        return {hasError: true, data: error};
    }

    render() {
        if (this.state.hasError) {
            console.error("-- APPLICATION HAS CRASHED --");
            console.error("Crash log:");
            console.error(this.state.data);

            return <UiContainer>
                <UiRow centered verticallyCentered>
                    <UiCol className={"text-md-left justify-content-center text-center d-sm-flex d-block"}>
                        <ErrorIcon className={"mr-sm-5 mr-0"}><FaBomb/></ErrorIcon>
                        <div>
                            <h1 className={"display-4"}>O nie!</h1>
                            <h3 className={"mb-0"}>Aplikacja uległa awarii</h3>
                            <UiButton label={"Powrót"} onClick={() => this.setState({hasError: false})}
                                      color={tinycolor("#ff3547")} className={"mx-0 py-3 px-4 mt-sm-2 mt-3"}>
                                Powrót
                            </UiButton>
                        </div>
                    </UiCol>
                </UiRow>
            </UiContainer>
        }
        return this.props.children;
    }
}

export default ErrorBoundary;