import App from "App";
import 'assets/scss/main.scss';
import ErrorBoundary from "ErrorBoundary";
import React from 'react';
import {createRoot} from 'react-dom/client';
import {HashRouter} from "react-router-dom";

navigator.serviceWorker && navigator.serviceWorker.register('sw.js');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<HashRouter><ErrorBoundary><App/></ErrorBoundary></HashRouter>);