import FingerprintJS from "@fingerprintjs/fingerprintjs";
import axios from "axios";
import React, {lazy, Suspense, useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import LoadingRouteUtil from "routes/LoadingRouteUtil";
import tinycolor from "tinycolor2";
import {UiThemeContext} from "ui";
import {popupError, popupWarning} from "utils/basic-utils";

const MenuRoute = lazy(() => import("routes/MenuRoute"));
const OrderSystemRoute = lazy(() => import("routes/OrderSystemRoute"));
const SelectRoute = lazy(() => import("routes/SelectRoute"));

axios.interceptors.response.use(undefined, error => {
    if (error.response === undefined) {
        popupError("Połączenie z serwerem nieudane.");
        return Promise.reject(error);
    }
    if (error.response.status === 500) {
        popupError("Błąd serwera.");
    }
    if (error.response.data.errors !== undefined) {
        error.response.data.errors.forEach(err => popupWarning(err));
    }
    return Promise.reject(error);
});

export const CLIENT_VERSION = "build 6 (26.09.2022)";
// Minimum Web Content Accessibility Guidelines contrast ratio for text
export const WCAG_AA_CONTRAST = 3.0;
export const API_ROUTE = "https://app.restauracja-bartek.pl";

const App = () => {
    const [session, setSession] = useState(null);
    useEffect(() => {
        new FingerprintJS.load().then(fp => fp.get().then(res => setSession(res.visitorId)));
    }, []);

    return <UiThemeContext.Provider value={{darkMode: false, getTheme: () => tinycolor("#44bd32"), theme: tinycolor("#44bd32"), defaultTheme: tinycolor("#44bd32")}}>
        <Suspense fallback={<LoadingRouteUtil/>}>
            <Switch>
                <Route exact path={"/"} component={() => <SelectRoute session={session}/>}/>
                <Route exact path={"/order"} component={() => <OrderSystemRoute session={session}/>}/>
                <Route exact path={"/menu"} render={() => <MenuRoute session={session}/>}/>
                <Route component={() => <SelectRoute session={session}/>}/>
            </Switch>
        </Suspense>
    </UiThemeContext.Provider>
};

export default App;